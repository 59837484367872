.img-cover{
    margin-top: 10px;
    margin-bottom: 3px;
    overflow: hidden;
    border-radius: 8px;
}

.center-card:hover .desc-link{
    text-decoration: underline;
}

.center-card:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.center-card{
    cursor: pointer;
    text-align: left;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}