.home-header{
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 9;
}
.custom-scroll::-webkit-scrollbar-track {
    background: #000000;
}
.custom-scroll::-webkit-scrollbar-thumb:horizontal{
    background: #ffffff;
    border-radius: 10px;
}
.selected{
    background-color: #ffffff !important;
    color: #000000 !important;
}
@media only screen and (max-width: 1140px) {
    .header{
        width: 100% !important;
    }
}
.mobile-search{
    display: block;
}
@media only screen and (max-width: 600px){
    .login-btn{
        font-size: 11px;
    }
}
.search-input:focus{
    border: none;
    outline: none;
}
.sub-cat-btn{
    padding: 5px 10px;
    margin: 0px 5px;
    color: #fff;
    display: inline-block;
    transition: all 0.5s;
    font-weight: 500;
    font-size: 0.875rem;
}

.sub-cat-btn:hover{
    border-bottom: 1px solid #fff;
}

.sub-cat-btn-active{
    border-bottom: 1px solid #fff;
}

.sub_group{
    background-color: #121727;
}