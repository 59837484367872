body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}
  
html {
    box-sizing: border-box;
}  
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  hr {
    border: 0;
    border-top: 1px solid #e2e8f0;
    margin: 2rem 0;
  }
  
  .text-blue-500 {
    color: #3b82f6;
  }