.footer-main{
    width: 100%;
    /* height: 65vh; */
    background-color: #131627;
    color: #fff;
}

.footer-inner{
    width: 1140px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 50px 23px 20px;
}
.text-center{
    text-align: center;
}
.w-100{
    width: 100%;
}
.mt-30{
    margin-top: 30px;
}
.footer-component{
    width: 25%;
}
.footer-middle-component{
    width: 50%;
}

.centered{
    padding: 20px;
}

.footer-header{
    color: #aab2bd !important;
    text-align: left;
    padding: 10px 20px;
}

.footer-input{
    width: 100%;
    background-color: #131627;
    border-bottom: 2px solid #aab2bd;
}
.footer-input:focus{
    outline: none;
}

.group{
    position: relative;
}

.group svg{
    position: absolute;
    top: 0%;
    right: 1%;
    fill: #fff;
}
::-webkit-input-placeholder { /* Edge */
    font-size: 18px;
    padding-left: 8px;
    padding-bottom: 8px;
}

.footer-image{  
    height: 200px;
    width: 100%;
    background-image: url("../../assets/6126238e144f7970c00d7c57_60f17a27f09c3f9cecf7ef53_footer-avatar.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.footer-third-part{
    padding: 20px;
}

.category-first{
    text-align: left;
}

.footer-forth{
    display: flex;
    justify-content: space-around;
}

.footer-details{
    padding: 10px 0px;
    color: #aab2bd;
    font-size: 16px;
    cursor: pointer;
}

@media only screen and (max-width: 1140px) {
    .footer-inner{
        width: 100%;
    }
}

/* @media only screen and (max-width: 660px) {
    .footer-first-input{
        width: 65% !important;
    }
    .footer-img{
        width: 35% !important;
    }
    .footer-forth{
        padding: 34px;
        justify-content: space-between;
    }
    .footer-component{
        width: 100%;
        flex-wrap: wrap;
    }
    .footer-third-part{
        width: 100%;
    }
    .category-first{
        padding-left: 20px;
    }
} */

@media only screen and (max-width: 660px) {
    .footer-first-input{
        width: 65% !important;
    }
    .footer-img{
        width: 35% !important;
    }
    .category-first{
        width: 100%;
        padding: 20px;
    }
    .footer-component{
        width: 100%;
        flex-wrap: wrap;
    }
    .footer-forth{
        padding: 0px !important;
    }
    .footer-middle-component{
        width: 100%;
    }

}

@media only screen and (max-width: 400px) {
    .footer-first-input{
        width: 100% !important;
    }
    .footer-img{
        display: none;
    }
}
