body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}
  
html {
    box-sizing: border-box;
}  
.about-section {
    padding: 50px;
    text-align: center;
    background-color: #474e5d;
    color: white;
}