@media only screen and (max-width: 900px) {
    .center-main{
        width: 100% !important;
    }
    .center{
        width: 94% !important;
        margin: 18px auto !important;
    }
    .side-bar-of-category{
        display: none;
    }
    .side-bar-of-news{
        display: none;
    }
}

.header{
    width: 1140px;
    margin: 0 auto;
}

.left-card div{
    padding: 0px;
    text-align: left;
}
.left-card .ant-card-body{
    padding: 12px;
}
.side-bar-of-category{
    width: 20%;
}
.left-cards{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    margin-top: 18px;
}
/* scroll baar  */ 

::-webkit-scrollbar{
    height: 3px;
    width: 4px;
    background: gray;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

::-webkit-scrollbar-thumb:horizontal{
    background: #000;
    border-radius: 10px;
}

/* end of scroll */

.leftside-first{
    padding-top: 10px;
}

.leftside-first h5 {
    text-align:center;
    text-transform:uppercase;
    font-size:18px; letter-spacing:1px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 16px 0;
    grid-gap: 22px;
}

.leftside-first h5:after,.leftside-first h5:before {
    content: " ";
    display: block;
    border-bottom: 2px solid #131627;
    background-color:#f8f8f8;
}

.center-heading{
    padding-top: 12.5px;
}

.center-heading h5 {
    text-align: center;
    font-size:30px; 
    font-weight:300; 
    color:#222; 
    letter-spacing:1px;
    text-transform: uppercase;    
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
}
    
.center-heading h5:after,.center-heading h5:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #131627;
    border-top: 1px solid #131627;
    height: 5px;
    background-color:#f8f8f8;
}

.view-all{
    color: white;
    background: #3b3c44;
    padding: 10px;
    cursor: pointer;
}

.main-part{
    width: 1140px;
    margin: 0 auto;
    display: flex;
}

.center{
    margin: 18px auto;
    width: 97.5%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    margin-top: 18px;
    padding-bottom: 18px;
}

.center-main{
    width: 72%;
    /* width: 60%; */
}

.side-bar-of-news{
    width: 27%;
    /* width: 20%; */
}
.sticky{
    top: 120px;
    position: sticky;
}
.menu-btn{
    /* display: none; */
}
@media only screen and (max-width: 600px) {
    .game{
        display: none;
    }
    .menu-btn{
        display: inherit;
    }
}
.pagination .ant-pagination-item-active , .pagination .ant-pagination-item-active:hover{
    border-color: #121727;
}
.pagination .ant-pagination-item-active a{
    color: #121727;
}
.pagination .ant-pagination-item-active a:hover{
    color: #121727;
}

.AuthorNameTag{
    display: inline-block;
    height: 25px;
    text-align: center;
    width: 25px;
    border-radius: 50%;
    border: 0.5px solid rgba(18, 23, 39, 0.7);
    margin-right: 7px;
    background-color: #d0d0d0;
}