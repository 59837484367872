body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}

html {
  box-sizing: border-box;
}
h1 {
  font-size: 2em;
  font-weight: bolder;
  display: block;
  margin: 10px 0;
}
h2 {
  font-size: 1.5em;
  font-weight: bolder;
  display: block;
  margin: 10px 0;
}
h3 {
  font-size: 1.17em;
  font-weight: bolder;
  display: block;
  margin: 10px 0;
}
h4 {
  font-size: 1em;
  font-weight: bolder;
  display: block;
  margin: 10px 0;
}
h5 {
  font-size: 0.83em;
  font-weight: bolder;
  display: block;
  margin: 10px 0;
}
h6 {
  font-size: 0.67em;
  font-weight: bolder;
  display: block;
  margin: 10px 0;
}
