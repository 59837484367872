.small-main{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.desc-link-2{
    font-family: 'Inter', sans-serif;
}
.small-details{
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    align-content: space-between;
    padding: 8px;
}

.center-img{
    padding: 0px 12px;
}

.small-image{
    height: 100%;
    width: 35%;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    padding-top: 25%;
    width: 35%;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.small-image img{
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.center-img-card{
    text-align: left;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    /* border-radius: 0px; */
}

.center-img-card:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;   
}
.center-img-card:hover .desc-link{
    text-decoration: underline;
}
.small-image-side img{
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.small-image-side{
    height: 100%;
    padding-top: 25%;
    width: 35%;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    /* border-radius: 5px; */
}

.desc-link{
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    font-family: 'Roboto Slab', serif;
}

.desc-link:hover{
    /* display: inline-block; */
    text-decoration: underline;
    /* border-bottom: 1px solid black; */
}

.img-card-padding{
    width: 100% !important;
    margin: 10px auto !important;
    border-radius: 0px;
}

.img-card-padding .ant-card-body{
    padding: 0px;
}

.set-image{
    width: 70%;
}

@media only screen and (max-width: 900px) {
    .small-image{
        width: 30%;
    }
    .small-details{
        width: 70%;
    }
    .set-image{
        width: 90%;
    }

}
.pagination .ant-pagination-item-active , .pagination .ant-pagination-item-active:hover{
    border-color: #121727;
}
.pagination .ant-pagination-item-active a{
    color: #121727;
}
.pagination .ant-pagination-item-active a:hover{
    color: #121727;
}

@media only screen and (max-width: 700px) {
    .small-image{
        width: 40%;
    }
    .small-details{
        width: 60%;
    }
}

@media only screen and (max-width: 550px) {
    .center-img{
        padding: 0 4px;
    }
    .last-update{
        font-size: 11px !important;
    }
    .small-image{
        width: 40%;
    }
    .small-details{
        width: 60%;
    }
    .set-image{
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .center-img{
        padding: 0 4px;
    }
    .desc-link{
        line-height: 1.2;
    }
    .desc-link-2{
        display: none;
    }
}