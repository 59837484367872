.add__user{
    max-width: 600px;
    margin: 6% auto !important;
    padding: 78px 144px 40px 100px !important;
    height: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
}

.buttons_of_adduser div{
    justify-content: center;
}
button, [type='button'], [type='reset'], [type='submit']{
    color: black;
}

@media only screen and (max-width: 600px) {
    .add__user{
        max-width: 90%;
        margin: 6% auto !important;
        padding: 18px 27px 21px 23px !important;
        height: auto;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background-color: rgb(255, 255, 255);
        border-radius: 12px;
    }
    .buttons_of_adduser{
        margin-left: -28px !important;
    }
}